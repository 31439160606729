import {FormControllerAPI} from '@wix/form-viewer';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {CustomFieldModel} from '../models/CustomField.model';
import {CashierMandatoryFieldsOverrides} from '../../types/app.types';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {
  FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER,
  FORMS_TEMPLATE_IDS,
  SPECS,
} from '../../components/Checkout/constants';
import {
  getContactFormInitialState,
  getContactFormOverrides,
} from '../../components/Checkout/Form/ContactForm/contactForm.utils';
import {
  getAddressFormInitialState,
  getAddressFormOverrides,
} from '../../components/Checkout/Form/AddressForm/addressForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getAdditionalInfoFormOverrides,
} from '../../components/Checkout/Form/AdditionalInfoForm/AdditionalInfoForm.utils';
import {getVatFormInitialState} from '../../components/Checkout/Form/VatIdForm/VatForm.utils';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getExtendedFieldsFormInitialState} from '../../components/Checkout/Form/ExtendedFieldsForm/ExtendedFieldsForm.utils';
import {ExtendedFieldsFragment} from '../../gql/graphql';
import {isCountryWithVat} from '../utils/isCountryWithVat';

export class FormsService {
  public validateForm: FormControllerAPI['validateForm'];
  private validationFunctionResolve!: (validateForm: FormControllerAPI['validateForm']) => void;
  private readonly siteStore: SiteStore;
  public isExtendedFieldsFormValid?: boolean;

  constructor({siteStore}: {siteStore: SiteStore}) {
    const validationFunctionPromise = new Promise<FormControllerAPI['validateForm']>((resolve) => {
      this.validationFunctionResolve = resolve;
    });

    this.validateForm = async (...args) => {
      const validate = await validationFunctionPromise;
      return validate(...args);
    };

    this.siteStore = siteStore;
  }

  public setFormValidator = (validateForm: FormControllerAPI['validateForm']) => {
    this.validationFunctionResolve(validateForm);
  };

  public setExtendedFieldsFormValid = async (extendedFields?: ExtendedFieldsFragment | null) => {
    const extendedFieldsErrors = await this.validateForm({
      formId: FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID,
      values: getExtendedFieldsFormInitialState(extendedFields ?? undefined),
    });
    this.isExtendedFieldsFormValid = !extendedFieldsErrors?.errors.length;
  };

  public isAddressValidForBilling = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      cashierMandatoryFields,
      checkoutComposerEnabled,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      cashierMandatoryFields: CashierMandatoryFieldsOverrides;
      checkoutComposerEnabled: boolean;
    }
  ): Promise<boolean> => {
    const addressTemplateNoCheckoutComposer = this.siteStore.experiments.enabled(
      SPECS.FixCheckoutAddressTemplateMandatoryZipCode
    )
      ? FORMS_TEMPLATE_IDS.ADDRESS_WITH_NON_MANDATORY_ZIP
      : FORMS_TEMPLATE_IDS.ADDRESS;
    const contactValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT
          : FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          overridePhone: cashierMandatoryFields?.phone,
          checkoutComposerEnabled,
        }),
        overrides: getContactFormOverrides({
          checkoutSettings,
          overridePhone: cashierMandatoryFields?.phone,
          checkoutComposerEnabled,
        }),
      });

    const addressValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS
          : addressTemplateNoCheckoutComposer,
        values: getAddressFormInitialState(checkoutSettings, checkoutComposerEnabled, addressWithContact.address),
        overrides: getAddressFormOverrides({
          checkoutSettings,
          overrides: cashierMandatoryFields,
          checkoutComposerEnabled,
        }),
      });

    const vatValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.VAT_ID,
        values: getVatFormInitialState(addressWithContact.contact),
        overrides: {},
      });

    const shouldValidateVat = isCountryWithVat(addressWithContact.address?.country);

    return validateAllForms([contactValidator, addressValidator, ...(shouldValidateVat ? [vatValidator] : [])]);
  };

  public isAddressValidForShipping = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      customField,
      isShippingFlow,
      checkoutComposerEnabled,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      customField?: CustomFieldModel;
      isShippingFlow: boolean;
      checkoutComposerEnabled: boolean;
    }
  ): Promise<boolean> => {
    const addressTemplateNoCheckoutComposer = this.siteStore.experiments.enabled(
      SPECS.FixCheckoutAddressTemplateMandatoryZipCode
    )
      ? FORMS_TEMPLATE_IDS.ADDRESS_WITH_NON_MANDATORY_ZIP
      : FORMS_TEMPLATE_IDS.ADDRESS;
    const contactValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT
          : FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          checkoutComposerEnabled,
        }),
        overrides: getContactFormOverrides({checkoutSettings, checkoutComposerEnabled}),
      });
    const shouldFilterByShippingCountries = this.siteStore.experiments.enabled(
      SPECS.FilterCountriesByShippingCountries
    );

    const addressValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS
          : addressTemplateNoCheckoutComposer,
        values: getAddressFormInitialState(
          checkoutSettings,
          checkoutComposerEnabled,
          addressWithContact.address,
          undefined,
          shouldFilterByShippingCountries
        ),
        overrides: getAddressFormOverrides({
          checkoutSettings,
          checkoutComposerEnabled,
          shouldFilterByShippingCountries,
        }),
      });

    const additionalInfoValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID
          : FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
        values: getAdditionalInfoFormInitialState(customField),
        overrides: getAdditionalInfoFormOverrides({checkoutSettings}),
      });

    return validateAllForms([contactValidator, ...(isShippingFlow ? [addressValidator] : []), additionalInfoValidator]);
  };
}

async function validateAllForms(formValidations: (() => Promise<{errors: any[]} | undefined> | undefined)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
